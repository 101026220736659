.world-list {
  max-width: 700px;
  margin: 20px auto 0;
  padding: 0 20px;
}

.world-list article {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
}

.world-list article p {
  font-size: 16px;
  color: #999;
  margin-top: 5px;
  line-height: 24px;
}
